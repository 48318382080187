@font-face {
  font-family: 'Open Sans';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('/fonts/OpenSans.ttf') format('ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('/fonts/OpenSans-Italic.ttf') format('ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}
